import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ThemeContext from '../../context/ThemeContext'

// Styles
import {
	Header,
	TitleColumn,
	Right,
	Left,
	White,
	RecommendedWrapp,
	More,
} from './style'

// Ui components
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Heading from '../../components/ui/Heading'
import Link from '../../components/ui/Link'
import Container from '../../components/ui/Container'
import ImageRightContentCta from '../../components/ui/ImageRightContentCta'
import ImageLeftContentCta from '../../components/ui/ImageLeftContentCta'
import PostRecommended from '../../templates/postRecommended'

const BlockChain = React.memo((props) => {
	const { theme } = useContext(ThemeContext)

	const data = useStaticQuery(graphql`
		query Blockchain {
			timacum {
				page(id: "5ffeb5fcc8ad8075e9852400") {
					Title
					Seo {
						TitleTag
						MetaDescription
					}
					Fields {
						... on TIMACUM_ComponentContentTitle {
							Title
							Layout
							Tag
						}
						... on TIMACUM_ComponentContentImageContentLeft {
							id
							GradientDark
							GradientLight
							Layout
							Text
							Title
							CTA
							Link
							Image {
								url
								alternativeText
								caption
								imageFile {
									publicURL
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
						... on TIMACUM_ComponentContentImageTitleText {
							id
							GradientDark
							GradientLight
							Layout
							Text
							Title
							CTA
							Link
							Image {
								url
								alternativeText
								caption
								imageFile {
									publicURL
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
					}
					posts {
						id
						Title
						ReadTime
						Seo {
							MetaDescription
							TitleTag
							Url
						}
						createdAt
						FeaturedImage {
							id
							url
							alternativeText
							caption
							imageFile {
								publicURL
								childImageSharp {
									fluid(maxWidth: 1920, quality: 100) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
						Field {
							... on TIMACUM_ComponentContentText {
								id
								Text
								Layout
							}
							... on TIMACUM_ComponentContentTitleText {
								id
								Title
								Layout
								Text
							}
						}
					}
				}
			}
		}
	`)

	const { Seo, posts } = data.timacum.page

	return (
		<Layout location={props.location} customCTA="White Label">
			<SEO data={Seo} />
			<Container>
				<TitleColumn>
					<span>BLOCKCHAIN</span>
					<Header as={Heading} level={1}>
						{data.timacum.page.Fields[0].Title}
					</Header>
				</TitleColumn>

				<Right
					as={ImageRightContentCta}
					fields={data.timacum.page.Fields[1]}
					level={2}
					baseLink
				/>

				<Left
					as={ImageLeftContentCta}
					fields={data.timacum.page.Fields[2]}
					level={2}
					baseLink
				/>

				<Right
					as={ImageRightContentCta}
					fields={data.timacum.page.Fields[3]}
					level={2}
					baseLink
				/>

				<Left
					as={ImageLeftContentCta}
					fields={data.timacum.page.Fields[4]}
					level={2}
					baseLink
				/>

				<White
					as={ImageRightContentCta}
					fields={data.timacum.page.Fields[5]}
					level={2}
					baseLink
				/>
				{posts.length !== 0 && (
					<RecommendedWrapp>
						<More as={Heading} level={2}>
							Read more about Blockchain on our{' '}
							<Link style={{ color: theme.link }} to="/blog">
								Blog
							</Link>
						</More>
						<PostRecommended posts={posts} />
					</RecommendedWrapp>
				)}
			</Container>
		</Layout>
	)
})

export default BlockChain
