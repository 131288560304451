import styled from 'styled-components'
import React from 'react'

export const TitleColumn = styled.div`
	max-width: 1180px;
	margin-bottom: 24em;

	span {
		font: 1rem/1.3125rem 'RobotoMono Bold';
		text-transform: uppercase;
		margin-bottom: 16px;
		display: block;
		color: white;
		mix-blend-mode: difference;
	}

	@media (max-width: 1024px) {
		margin-bottom: 128px;
	}
`

export const Header = styled.h1`
	&.title {
		font-size: 2.625rem;
		line-height: 3.75rem;

		@media (max-width: 1250px) {
			font-size: 2.625rem;
			line-height: 3.75rem;
		}

		@media (max-width: 1024px) {
			font-size: 32px;
			line-height: 44px;
		}

		@media (max-width: 525px) {
			max-width: 322px;
			font-size: 30px;
			line-height: 44px;
		}
	}
`

export const Right = styled.div`
	.title {
		max-width: 545px;
	}

	.image-wrapp {
		margin-top: 36px;
		text-align: center;
	}

	@media (max-width: 1250px) {
		.image-wrapp {
			margin-top: 0;

			img {
				width: 30%;
			}
		}
	}

	@media (max-width: 525px) {
		.image-wrapp {
			img {
				width: 54%;
			}
		}
	}
`
export const Left = styled.div`
	margin-bottom: 256px;

	div.content {
		padding-right: 0;
		padding-left: 94px;

		.title {
			max-width: 100%;
		}
	}

	div.s-image {
		padding-right: 0;
		flex-basis: calc(50% - 64px);
		max-width: calc(50% - 64px);

		.image-wrapp {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	@media (max-width: 1250px) {
		margin-bottom: 128px;

		div.content {
			padding: 0;
			flex-basis: 100%;
			max-width: 100%;
		}

		div.s-image {
			flex-basis: 100%;
			max-width: 100%;
			order: 1;
			padding: 60px 0;

			.image-wrapp {
				margin: 0;

				.gatsby-image-wrapper {
					/* margin: 60px 0; */
					max-height: 100%;
					top: unset;
					transform: unset;
				}

				img {
					width: 30%;
				}
			}
		}
	}
	@media (max-width: 525px) {
		margin-bottom: 96px;

		div.s-image {
			margin-bottom: 0;
			padding-bottom: 0;

			.image-wrapp {
				img {
					width: 45%;
				}
			}
		}
	}
`

export const White = styled.div`
	.title {
		max-width: 545px;
	}

	.gatsby-image-wrapper {
		max-height: unset !important;
		max-width: 80%;
		margin: 0 auto;
	}

	.content-item {
		position: relative;
	}

	@media (max-width: 1250px) {
		.gatsby-image-wrapper {
			max-width: 64%;
		}
	}
`
export const RecommendedWrapp = styled.div`
	.post-recommended {
		margin-bottom: 0;
	}

	h2 {
		margin-bottom: 64px;
		font-size: 30px;
	}

	@media (pointer: coarse), (max-width: 1024px) {
		.post-recommended {
			margin-bottom: 42px;
		}

		h2 {
			font-size: 21px;
			margin-bottom: 36px;
		}
	}

	@media (max-width: 768px) {
		.post-recommended {
			margin-bottom: 36px;
		}

		h2 {
			font-size: 18px;
			margin-bottom: 36px;
		}
	}
`
export const More = styled.h2`
	a {
		font: inherit;
		color: ${({ theme }) => theme.blendLink};
		mix-blend-mode: difference;
		text-transform: capitalize;
		transition: opacity 0.25s;

		&:hover {
			opacity: 0.7;
		}
	}
`
/******************************************
 * Gatsby bug (file must export component)
 *****************************************/
const Style = () => {
	return <div>style</div>
}

export default Style
