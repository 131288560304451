import React, { useContext } from 'react'
// import slugify from 'slugify'
import HoverContext from '../context/HoverContext'

// Gatsby
import Img from 'gatsby-image'

// Styles
import { List, ListWrap, FImage } from './style'

// Components
import Heading from '../components/ui/Heading'
import Paragraph from '../components/ui/Paragraph'
import Link from '../components/ui/Link'

const PostRecommended = ({ posts }) => {
	const { setCursorStyle } = useContext(HoverContext)
	return (
		<List>
			{posts.map((post, index) => {
				var monthNames = [
					'Jan',
					'Feb',
					'March',
					'April',
					'May',
					'June',
					'July',
					'Aug',
					'Sep',
					'Oct',
					'Nov',
					'Dec',
				]
				let date = new Date(post.createdAt)

				// const slug = slugify(post.Title.replace(/-/g, ' '), {
				// 	lower: true,
				// 	remove: /[?*+~.()'"!:@]/g,
				// })

				return (
					<ListWrap key={index} className="post-recommended">
						{post.FeaturedImage && (
							<Link
								className="img-wrap"
								to={'/post/' + post.Seo.Url}
								onMouseEnter={() => {
									setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
								}}
								onMouseLeave={() => {
									setCursorStyle({
										height: false,
										width: false,
										transition: false,
									})
								}}
							>
								<FImage
									as={Img}
									fluid={post.FeaturedImage.imageFile.childImageSharp.fluid}
									alt={post.FeaturedImage.alternativeText}
									title={post.FeaturedImage.caption}
								/>
							</Link>
						)}
						<div className="text-wrapp">
							<div className="top-info">
								<Paragraph>
									{monthNames[date.getMonth()]} {date.getMonth()}
								</Paragraph>
								<span
									style={{
										mixBlendMode: 'difference',
										backgroundColor: 'white',
									}}
								></span>
								<Paragraph>{post.ReadTime}</Paragraph>
							</div>
							<Link
								className="title-link"
								to={'/post/' + post.Seo.Url}
								onMouseEnter={() => {
									setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
								}}
								onMouseLeave={() => {
									setCursorStyle({
										height: false,
										width: false,
										transition: false,
									})
								}}
							>
								<Heading level={3}>
									{(() => {
										if (index !== 0) {
											return post.Title.length > 65
												? post.Title.substring(0, 65) + ' ...'
												: post.Title
										} else {
											return post.Title.length > 90
												? post.Title.substring(0, 90) + ' ...'
												: post.Title
										}
									})()}
								</Heading>
							</Link>
							{(() => {
								if (post.Field.length > 0) {
									const counter = post.Field.length - 1 ? 136 : 72

									if (post.Field[0].Layout === 'text') {
										return (
											<Paragraph>
												{post.Field[0].Text.length > counter
													? post.Field[0].Text.substring(0, counter) + ' ...'
													: post.Field[0].Text}
											</Paragraph>
										)
									} else {
										return (
											<React.Fragment>
												<Paragraph>
													{post.Field[0].Text.length > counter
														? post.Field[0].Text.substring(0, counter) + ' ...'
														: post.Field[0].Text}
												</Paragraph>
											</React.Fragment>
										)
									}
								}
							})()}
							<Link
								className="link"
								to={'/post/' + post.Seo.Url}
								onMouseEnter={() => {
									setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
								}}
								onMouseLeave={() => {
									setCursorStyle({
										height: false,
										width: false,
										transition: false,
									})
								}}
							>
								Read more
							</Link>
						</div>
					</ListWrap>
				)
			})}
		</List>
	)
}

export default PostRecommended
